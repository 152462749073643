import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/Vince/Documents/GitHub/pp-jn-website/src/components/Layout/Layout.js";
import { graphql } from "gatsby";
import SEO from "layout/SEO";
import Completed from "../../components/Completed/Completed";
export const query = graphql`
  query {
    completed: file(relativePath: { eq: "complete__medicines.png" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEO title="Submission Completed" mdxType="SEO" />
    <Completed icon={props.data.completed.childImageSharp.fluid} mdxType="Completed">
      <p className="py-1">
  A MedGrocer representative will contact you to confirm your order. If you
  don't receive a confirmation email, please contact us at{" "}
  <a href={`mailto:${process.env.GATSBY_AWS_SES_EMAIL_TO}`} className="has-text-weight-bold">
    {process.env.GATSBY_AWS_SES_EMAIL_TO}
  </a>
  .
      </p>
      <p className="pb-1">
  Orders are not final until verified by our pharmacist via SMS.
      </p>
    </Completed>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      